import { Divider } from "antd";
import { Hero } from "../components/landingPage/hero";
import { Location } from "../components/landingPage/location";
import { Footer } from "../components/navigation/footer";
import { DynamicMetaTags } from "../utils/dynamicMetaTags";
import ogImage from "../images/ogImage.png";

import { DetailCards } from "../components/landingPage/detailCards";
import { YouTube } from "../components/landingPage/youTube";
import { Project } from "../components/landingPage/projects";
import { Services } from "../components/landingPage/services";

export const LandingPage = ({ helloWorld }) => {
  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <DynamicMetaTags ogTitle={`Sonifex`} ogImage={ogImage} />
      <div>
        <Hero helloWorld={helloWorld} />
        <Divider>Featured Products</Divider>
        <DetailCards />
        <Divider>Product Demos</Divider>
        <YouTube />
        <Divider />
        <Project />
        <Divider />
        <Services />
        <Location />
        <Footer />
      </div>
    </div>
  );
};
