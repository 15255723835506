export const Location = () => {
  return (
    <iframe
      id="googleMap"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.4002337550673!2d79.88777621554505!3d6.9620235949703435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae259fe675acb2d%3A0xb9a8bb25c522de87!2sPeoples%20Media%20Network%20(Pvt)%20Ltd!5e0!3m2!1sen!2suk!4v1670231879827!5m2!1sen!2suk"
      width="100%"
      height="450"
      title="Google Map of PMN"
      style={{ border: "0", marginBottom: "15px" }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};
