import { Col, Divider, Row, Space } from "antd";
import { ContactUs } from "../landingPage/contactUs";

export const Footer = () => {
  return (
    <div style={{ margin: "20px" }}>
      <Row gutter={[18, 18]}>
        <Divider></Divider>
        <Col xs={24} sm={24} md={9}>
          <p style={{ fontWeight: "900" }}>Peoples Media Network</p>
          <p style={{ fontSize: "14px" }}>
            {" sonifex.lk PMN is the sole "}
            <a
              style={{
                fontSize: "14px",
                // textDecoration: "none",
                color: "salmon",
              }}
              href="https://www.sonifex.co.uk/company/distributors/index.asp"
              target={"_blank"}
              rel={"noreferrer"}
            >
              {"authorised distributor"}
            </a>
            {" of Sonifex UK in Sri Lanka"}
          </p>
          <Space>
            <a
              style={{
                fontSize: "14px",
                // textDecoration: "none",
                color: "salmon",
              }}
              href={"https://eastwest.lk"}
            >
              {"East West Properties PLC "}
              {/* <img
                style={{ height: "15px" }}
                src={
                  "https://jdevs-app.s3.eu-west-2.amazonaws.com/04-slack-artwork/slack-2.svg"
                }
                alt={"slack logo"}
              /> */}
            </a>
          </Space>
          <p>
            Our Company - East West Properties PLC is a publically listed on the
            Colombo Stock Exchange.
          </p>
        </Col>
        <Col xs={24} sm={24} md={7}>
          <p style={{ fontWeight: "700" }}>Company</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://eastwest.lk"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>
          </ul>
          <p style={{ fontWeight: "700" }}>
            SALES, SERVICE AND INFERTUCTURE PROJECTS
          </p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <p
                style={{
                  fontSize: "14px",
                  color: "salmon",
                }}
              >
                Daya Buddhadasa - MANAGER
              </p>
            </li>
            <li>
              Mobile:
              <a href="https://wa.me/94714917206">+94 71 4917206</a>
            </li>
            <li>
              Hotline:
              <a href="tel:+94712244442">+94 71 2244442 </a>
            </li>
            <li>
              Office:
              <a href="tel:+94114845443">+94 11 4845443</a>
            </li>
            <li>
              Fax:
              <a href="tel:+94112919239">+94 11 2919239</a>
            </li>
            <li>
              <a href="mailto:daya@eastwest.lk">daya@eastwest.lk</a>
            </li>
          </ul>
          <p style={{ fontWeight: "700" }}>
            TECHNICAL SUPPORT &amp; SERVICE INQUIRY
          </p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <p
                style={{
                  fontSize: "14px",
                  color: "salmon",
                }}
              >
                Clarence Manual - Senior Engineer
              </p>
            </li>
            <li>
              Mobile
              <a href="https://wa.me/94771556340">+94 77 1556340</a>
            </li>
            <li>
              Office:
              <a href="tel:+94114845439">+94 11 4845439</a>
            </li>
            <li>
              Fax:
              <a href="tel:+94112919239">+94 11 2919239</a>
            </li>
            <li>
              <a href="mailto:clarence@ew.lk">clarence@ew.lk</a>
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ContactUs />
        </Col>
        <Divider></Divider>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "12px" }}>sonifex.lk &copy; 2023</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
