import { Button, Col, Form, Input, Row, Card, Modal } from "antd";
import axios from "axios";

export const ContactUs = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const postSlackMessage = ({ params }) => {
    return axios
      .post(".netlify/functions/postSlackMessageChannel", {
        params,
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function error(error) {
        const errorMessage = error.response.data;
        console.log(errorMessage);
      });
  };

  const onFinish = (values) => {
    form.validateFields().then((values) => {
      const success = () => {
        Modal.success({
          content:
            "Thanks for getting in touch with us... Will get in touch ASAP!!",
        });
      };
      const slackMessageData = {
        params: {
          url: "https://hooks.slack.com/services/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",
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: "Contact Message from storage.lk!",
                emoji: true,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `Name: ${values.name} \n\n Email: ${values.email} \n\n Contact Number: ${values.number} \n\n Comments: ${values.message}  `,
              },
            },
          ],
        },
      };
      postSlackMessage(slackMessageData);
      success();
      form.resetFields();
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const ContactForm = () => {
    return (
      <Card
        title={"Contact Us"}
        bordered={true}
        style={{
          textAlign: "center",
          marginBottom: "20px",
          height: "90%",
          backgroundColor: "rgb(225, 225, 219)",
        }}
      >
        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={18}>
            <Col xs={24} sm={24} md={8} lg={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please input your name" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={10}>
              <Form.Item
                label="Contact Number"
                name="number"
                rules={[
                  {
                    required: true,
                    message: "Please input your contact number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={14}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label=""
            name="message"
            rules={[{ required: false, message: "Please input your email!" }]}
          >
            <TextArea placeholder="send us details of your enquiry" rows={3} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  };

  return (
    <Row gutter={10}>
      <Col xs={24} sm={24} md={24} lg={24}>
        <ContactForm />
      </Col>
    </Row>
  );
};
