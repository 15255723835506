import { Col, Row } from "antd";

import heroImage from "../../images/sonifex-logo.svg";
// import { WhatsAppOutlined } from "@ant-design/icons";

export const Hero = () => {
  return (
    <Row
      style={{
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        background: "rgb(225, 225, 219)",
        // paddingTop: "15px",

      }}
    >
      <Col xs={24} sm={24} md={24}>
        <div
          style={{
            // background: "rgb(225, 225, 219)",
            // margin: "40px",
            textAlign: "center",
            height: "50vh"
          }}
        >
          <img style={{ height: "100%" }} src={heroImage} alt="logo" />
        </div>
      </Col>
    </Row>
  );
};
