import { Col, Row } from "antd"

export const YouTube = () => {
    return (
        <Row gutter={[18, 18]} style={{ margin: "15px" }}>
            <Col xs={24} sm={24} md={6}>
                <iframe
                    width="100%"
                    height="315px"
                    src="https://www.youtube.com/embed/w_emD2Z-xNI?start=11"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </Col>
            <Col xs={24} sm={24} md={6}>
                <iframe
                    width="100%"
                    height="315px"
                    src="https://www.youtube.com/embed/DnfGbPQLo2U?start=11"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </Col>
            <Col xs={24} sm={24} md={6}>
                <iframe
                    width="100%"
                    height="315px"
                    src="https://www.youtube.com/embed/0kuWPWXzDOM?start=11"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </Col>
            <Col xs={24} sm={24} md={6}>
                <iframe
                    width="100%"
                    height="315px"
                    src="https://www.youtube.com/embed/Zgh2zHS2TAg?start=11"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </Col>
        </Row >
    )
}