import { Card, Col, Row } from "antd";
import avn from "../../images/avn-dio12-iso.jpeg";
import aio from "../../images/avn-aio8-iso.jpeg";
import dac from "../../images/rb-dac1.jpeg";


export const DetailCards = () => {
  return (
    <div className="site-card-wrapper">
      <Row gutter={[18, 16]}>
        <Col xs={24} sm={24} md={8}>
          <a style={{ textDecoration: "none" }} href="https://www.sonifex.co.uk/avn/avn-dio12.shtml" target={"_blank"} rel={"noreferrer"}>
            <Card bordered={true} title={"AVN-DIO12 Dual Mono"}
            >
              <Row gutter={18} style={{ height: "100px" }}>
                <Col span={10}>
                  <img
                    style={{ width: "100%" }}
                    src={avn}
                    alt={"security sheild"}
                  />
                </Col>
                <Col span={14} style={{
                  height: "100px",
                  overflow: "scroll"
                }}>


                  {
                    "The AVN-DIO12 is a dual microphone input to Dante® converter with adjustable mic gain in the Sonifex DIO range of Dante® input/output devices. It’s effectively 2 x AVN-DIO09 units in a single chassis, still offering A/D circuitry with a world-class E.I.N. of 129dB."
                  }

                </Col>
              </Row>
            </Card>
          </a>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <a style={{ textDecoration: "none" }} href="https://www.sonifex.co.uk/avn/avn-aio8.shtml" target={"_blank"} rel={"noreferrer"}>
            <Card bordered={true} title={"AVN-AIO8 8 Input"}>
              <Row gutter={18} style={{ height: "100px" }}>
                <Col span={10}>
                  <img
                    style={{ width: "100%" }}
                    src={aio}
                    alt={"access key"}
                  />
                </Col>
                <Col span={14} style={{
                  height: "100px",
                  overflow: "scroll"
                }}>
                  {"The AVN-AIO8 audio converter and interface converts up to eight analogue inputs and eight analogue outputs to and from the Dante Audio-over-IP networking standard. This cost effective 1U rack-mount unit offers an easy solution for AV professionals and system integrators. It is simple to configure and operate, with all set-up, except line-up levels, done via the standard Dante Controller software and power via PoE (Power Over Ethernet)."}
                </Col>
              </Row>
            </Card>
          </a>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <a style={{ textDecoration: "none" }} href="https://www.sonifex.co.uk/redbox/rbdac1_ld.shtml" target={"_blank"} rel={"noreferrer"}>
            <Card bordered={true} title={"RB-DAC1 D/A Converter"}>
              <Row gutter={18} style={{ height: "100px" }}>
                <Col span={10}>
                  <img
                    style={{ width: "100%" }}
                    src={dac}
                    alt={"access key"}
                  />
                </Col>
                <Col span={14} style={{
                  height: "100px",
                  overflow: "scroll"
                }}>
                  {"Using 24 bit, 192kHz capable devices, the RB-DAC1 D/A Converter is a 1U rack-mount which produces a stereo balanced XLR or unbalanced phono output from an incoming AES/EBU or S/PDIF digital input signal. There is also a headphone output with volume control for monitoring purposes."}
                </Col>
              </Row>
            </Card>
          </a>
        </Col>
      </Row>
    </div>
  );
};
