import { Col, Divider, Image, Row } from "antd";

export const Project = () => {
  return (
    <div style={{ margin: "10px" }}>
      <Divider>Sonifex S2 Mixers at ABC Network Studio</Divider>
      <Row gutter={[18, 18]}>
        <Col xs={24} sm={24} md={6}>
          <Image
            alt="mixer"
            src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-sonifex/projects/abc-mixer-view.jpeg"
          />
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Image
            alt="playout-system"
            src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-sonifex/projects/abc-playout-system.jpeg"
          />
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Image
            alt="abc-pmn-team"
            src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-sonifex/projects/abc-pmn-team.jpeg"
          />
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Image
            alt="sonifex-team"
            src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-sonifex/projects/group-sonifex-team.jpeg"
          />
        </Col>
      </Row>
    </div>
  );
};
