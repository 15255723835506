import { Col, Divider, Row } from "antd";

export const Services = () => {
  return (
    <Row gutter={[18, 18]} style={{ margin: "15px" }}>
      <Col xs={24} sm={24} md={12}>
        <Divider>Sale & Hire</Divider>
        <p>
          We are available round the clock, 24 hours a day, 7 days a week, and
          365 days a year to provide you with competitive prices for purchasing
          all kinds of new broadcast equipment or renting them for emergency or
          event use. Please contact us on our hotline at any time.
        </p>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Divider>Our Customers</Divider>
        <p>
          We have sold &amp; rented Broadcast Equipment to the leading
          Broadcasters in Sri Lanka Here are some customers
        </p>
        <ul style={{ listStyleType: "none", padding: "0px" }}>
          <li>THE BUDDHIST MEDIA NETWORK</li>
          <li>INDEPENDENT TELEVISION NETWORK</li>
          <li>ASIA BROADCASTING CORPORATION</li>
          <li>MBC NETWORK</li>
          <li>POWER HOUSE LTD (DERANA FM)</li>
        </ul>
      </Col>
    </Row>
  );
};
